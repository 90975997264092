import { observable } from 'mobx';
import type { SnakeCasedProperties } from 'type-fest';
import adminApi from 'src/api/admin-api';
import type { GlideApiResponse } from 'src/api/base-api';
import type { FetchPspdfkitDocumentOptions } from 'src/api/public/documents';
import type Documents from 'src/api/public/documents';
import type { PspdfkitDocument } from 'src/types/proto/services/pspdfkit_public_service';
import { downloadBlob } from 'src/utils/download-blob';
import { getFetch } from 'src/utils/get-fetch';
import type { AppStore } from './app-store';

export interface FetchPspdfkitDocConfig {
  doc?: { uuid: string; url: string };
  fillableFormId?: string;
  pspdfkitFetchDocOptions?: FetchPspdfkitDocumentOptions;
}

export type PspdfkitDocResponse = GlideApiResponse<
  SnakeCasedProperties<PspdfkitDocument>
>;

export default class DocumentsStore {
  parent: AppStore;
  api: Documents;

  constructor(parent: AppStore) {
    this.parent = parent;
    this.api = this.parent.api.documents;
  }

  @observable fillConfigByFormUuid = new Map();

  bulkDownload = async (
    transactionId: string,
    filename: string,
    tdvIds: string[]
  ) => {
    const blob = await this.api.getZipBlobByTdvIds({
      transactionId,
      tdvIds,
      filename,
    });
    return downloadBlob(blob, filename);
  };

  fetchPspdfkitDoc = async (config: FetchPspdfkitDocConfig) => {
    const { doc, fillableFormId, pspdfkitFetchDocOptions } = config;

    try {
      let pspdfkitDocData;
      if (fillableFormId) {
        pspdfkitDocData = await this.api.getPspdfkitDocumentByForm(
          fillableFormId
        );
      } else if (doc && doc.uuid) {
        pspdfkitDocData = await this.api.getPspdfkitDocumentByDoc(
          doc.uuid,
          pspdfkitFetchDocOptions || {}
        );
      } else if (doc && doc.url) {
        pspdfkitDocData = await this.api.getPspdfkitDocumentByUrl(
          doc.url,
          pspdfkitFetchDocOptions || {}
        );
      } else {
        // nothing to fetch
        return true;
      }

      if (
        pspdfkitDocData &&
        (pspdfkitDocData.status !== 200 || !pspdfkitDocData.data)
      ) {
        throw new Error(
          `${pspdfkitDocData.status} ${(
            pspdfkitDocData.data || ''
          ).toString()}`.trim()
        );
      }
      return pspdfkitDocData.data;
    } catch (err) {
      this.parent.ui.wentWrong(err);
      return null;
    }
  };

  fetchAnnotateData = async (transactionId: string, tdvId: string) => {
    try {
      const annotateData = await this.api.getPspdfkitEditableDocumentByTdv(
        transactionId,
        tdvId
      );
      if (annotateData && (annotateData.status !== 200 || !annotateData.data)) {
        throw new Error(
          `${annotateData.status} ${(
            annotateData.data || ''
          ).toString()}`.trim()
        );
      }
      return annotateData.data;
    } catch (err) {
      this.parent.ui.wentWrong(err);
      return null;
    }
  };

  getFetchOcrTerms = getFetch({
    bindTo: this,
    fetcher: () => adminApi.documents.getOcrTerms().then(({ data }) => data),
  });
}
